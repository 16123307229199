import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { createUserMessage } from "../../api/userMessage";
import instagramIcon from "../../assets/instagramIcon.svg";
import telegramIcon from "../../assets/telegramIcon.svg";
import whatsappIcon from "../../assets/whatsappIcon.svg";
import { footerNavigationSections } from "../../data";
import { getSession } from "../../storage/session";
import { scrollTo } from "../../utils";
import "./Footer.css";

export default function Footer({ mobileView, browserView }) {
  const initialMessage = () => ({
    mail: "",
  });

  const user = getSession();

  const [userMessage, setUserMessage] = useState(initialMessage);

  const getUserMessage = (event) => {
    const { name, value } = event.target;
    setUserMessage({ ...userMessage, [name]: value });
  };

  const submitUserMessage = () => {
    createUserMessage(userMessage, user.uid);
    setUserMessage(initialMessage());
  };
  return (
    <div>
      {browserView && (
        <div className="footerContainer">
          <div className="footerFirstBlock">
            <span className="footerTitle">DARIYA YOGA</span>
            <span className="footerText underLogoText">
              <FormattedMessage id="footerText" />
            </span>
            <span className="footerText contactInfo">
              6, 10, Rue de la Confédération 8, 1204 Geneva
            </span>
            <span className="footerText">dariya@yoga-alimpieva.ru</span>
            <a
              className="footerText privacyPolicy"
              target="blank"
              href="https://docs.google.com/document/d/1jYIOKz5sSnx3K1kN8PTKTTuyvbe3j9hO/edit?usp=sharing&ouid=108162605365857697436&rtpof=true&sd=true"
            >
              <FormattedMessage id={"policy"} />
            </a>
          </div>
          <div className="footerSecondBlock">
            <TextField
              className="userCommentEmail"
              label={<FormattedMessage id="userCommentEmail" />}
              variant="standard"
              name="mail"
              required
              value={userMessage.mail}
              onChange={getUserMessage}
              sx={{ mb: 3 }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="emailIcon"
                      aria-label="toggle password visibility"
                      onClick={submitUserMessage}
                      edge="end"
                    >
                      <ArrowRightAltIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="navigationLinksBox">
              <div className="navigationBlock">
                <Link to={`/booking`} className="footerNavBtn">
                  <FormattedMessage id="section_booking" />
                </Link>

                {footerNavigationSections.map((section) => (
                  <span
                    key={section.id + "sectionFooter"}
                    onClick={() => scrollTo(section.id)}
                    variant="text"
                    className="footerNavBtn"
                  >
                    <FormattedMessage id={"section_" + section.id} />
                  </span>
                ))}
              </div>
              <div>
                <a
                  href="https://chat.whatsapp.com/Jna2D07iUDQ9n0Dn8MkIxF"
                  className="footerIcon"
                >
                  <img src={whatsappIcon} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/dariya_yoga?igsh=Z3F5YTF0cGJqd3pk&utm_source=qr"
                  className="footerIcon"
                >
                  <img src={instagramIcon} alt="" />
                </a>
                <a href="https://t.me/Dariya_Yoga" className="footerIcon">
                  <img src={telegramIcon} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      {mobileView && (
        <div className="footerContainer">
          <div className="footerFirstBlock">
            <span className="footerTitle">DARIYA YOGA</span>
            <span className="footerText underLogoText">
              <FormattedMessage id="footerText" />
            </span>
          </div>
          <div className="footerSecondBlock">
            <TextField
              className="userCommentEmail"
              label={<FormattedMessage id="userCommentEmail" />}
              variant="standard"
              name="mail"
              required
              value={userMessage.mail}
              onChange={getUserMessage}
              sx={{ mb: 3 }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="emailIcon"
                      aria-label="toggle password visibility"
                      onClick={submitUserMessage}
                      edge="end"
                    >
                      <ArrowRightAltIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="navigationLinksBox">
              <div className="navigationBlock">
                <Link to={`/booking`} className="footerNavBtn">
                  <FormattedMessage id="section_booking" />
                </Link>

                {footerNavigationSections.map((section) => (
                  <span
                    key={section.id + "sectionFooter"}
                    onClick={() => scrollTo(section.id)}
                    variant="text"
                    className="footerNavBtn"
                  >
                    <FormattedMessage id={"section_" + section.id} />
                  </span>
                ))}
              </div>
              <div className="footerAddressBlock">
                <span className="footerText contactInfo">
                  6, 10, Rue de la Confédération 8, 1204 Geneva
                </span>
                <span className="footerText">dariya@yoga-alimpieva.ru</span>
                <a
                  className="footerText privacyPolicy"
                  target="blank"
                  href="https://docs.google.com/document/d/1jYIOKz5sSnx3K1kN8PTKTTuyvbe3j9hO/edit?usp=sharing&ouid=108162605365857697436&rtpof=true&sd=true"
                >
                  <FormattedMessage id={"policy"} />
                </a>
                <div className="footerAddressBlockIcons">
                  <a
                    href="https://chat.whatsapp.com/Jna2D07iUDQ9n0Dn8MkIxF"
                    className="footerIcon"
                  >
                    <img src={whatsappIcon} alt="" />
                  </a>
                  <a
                    href="https://instagram.com/daria_danilovich?igshid=YmMyMTA2M2Y="
                    className="footerIcon"
                  >
                    <img src={instagramIcon} alt="" />
                  </a>
                  <a href="" className="footerIcon">
                    <img src={telegramIcon} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
